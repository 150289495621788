// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apple-card-details-js": () => import("./../../../src/pages/apple-card/details.js" /* webpackChunkName: "component---src-pages-apple-card-details-js" */),
  "component---src-pages-body-insights-index-js": () => import("./../../../src/pages/body-insights/index.js" /* webpackChunkName: "component---src-pages-body-insights-index-js" */),
  "component---src-pages-body-insights-privacy-js": () => import("./../../../src/pages/body-insights/privacy.js" /* webpackChunkName: "component---src-pages-body-insights-privacy-js" */),
  "component---src-pages-cinematic-index-js": () => import("./../../../src/pages/cinematic/index.js" /* webpackChunkName: "component---src-pages-cinematic-index-js" */),
  "component---src-pages-cinematic-privacy-js": () => import("./../../../src/pages/cinematic/privacy.js" /* webpackChunkName: "component---src-pages-cinematic-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-math-flash-index-js": () => import("./../../../src/pages/math-flash/index.js" /* webpackChunkName: "component---src-pages-math-flash-index-js" */),
  "component---src-pages-math-flash-privacy-js": () => import("./../../../src/pages/math-flash/privacy.js" /* webpackChunkName: "component---src-pages-math-flash-privacy-js" */),
  "component---src-pages-study-sets-components-details-header-js": () => import("./../../../src/pages/study-sets/components/DetailsHeader.js" /* webpackChunkName: "component---src-pages-study-sets-components-details-header-js" */),
  "component---src-pages-study-sets-components-first-details-js": () => import("./../../../src/pages/study-sets/components/FirstDetails.js" /* webpackChunkName: "component---src-pages-study-sets-components-first-details-js" */),
  "component---src-pages-study-sets-components-second-details-js": () => import("./../../../src/pages/study-sets/components/SecondDetails.js" /* webpackChunkName: "component---src-pages-study-sets-components-second-details-js" */),
  "component---src-pages-study-sets-components-third-details-js": () => import("./../../../src/pages/study-sets/components/ThirdDetails.js" /* webpackChunkName: "component---src-pages-study-sets-components-third-details-js" */),
  "component---src-pages-study-sets-index-js": () => import("./../../../src/pages/study-sets/index.js" /* webpackChunkName: "component---src-pages-study-sets-index-js" */),
  "component---src-pages-study-sets-privacy-js": () => import("./../../../src/pages/study-sets/privacy.js" /* webpackChunkName: "component---src-pages-study-sets-privacy-js" */)
}

